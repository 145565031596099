
// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

// --------------------------------------------------
// About nav
// --------------------------------------------------

.blocks-nav {
  @extend .site-max-width;
  @extend .site-block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .blocks-nav-link {
    padding: 10px 15px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    background-color: #4c4c4c;
    transition: .5s;

    &.link-0:hover, &.link-0.is-active {
      background-color: $colorM1;
    }

    &.link-1:hover, &.link-1.is-active {
      background-color: $colorM2;
    }

    &.link-2:hover, &.link-2.is-active {
      background-color: $colorM3;
    }

    &.link-3:hover, &.link-3.is-active {
      background-color: $colorM4;
    }

    &.link-4:hover, &.link-4.is-active {
      background-color: $colorM5;
    }

    &.link-5:hover, &.link-5.is-active {
      background-color: $colorM6;
    }

    &.link-6:hover, &.link-6.is-active {
      background-color: $colorM7;
    }

    &.link-7:hover, &.link-7.is-active {
      background-color: $colorM8;
    }

    &.link-8:hover, &.link-8.is-active {
      background-color: $colorM9;
    }

    &.link-9:hover, &.link-9.is-active {
      background-color: $colorM10;
    }

    &.link-10:hover, &.link-10.is-active {
      background-color: $colorM11;
    }

    &.link-11:hover, &.link-11.is-active {
      background-color: $colorM12;
    }

    &.link-12:hover, &.link-12.is-active {
      background-color: $colorM13;
    }

    &.link-13:hover, &.link-13.is-active {
      background-color: $colorM14;
    }

    &.link-14:hover, &.link-14.is-active {
      background-color: $colorM15;
    }


  }
}

// --------------------------------------------------
// Hero Banner
// --------------------------------------------------

.hero-wrapper {
  position: relative;
  display: block;
  background-color: $dark;

  // padding-bottom: 100px;

  @media (max-width: 630px) {
    padding-bottom: 85px;
  }

  @media (max-width: 460px) {
    // padding-bottom: 60px;
  }

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &.overlay-dark {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .50);
      z-index: 1;
    }
  }

  .hero-caption {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 500px;
    width: 100%;
    padding: 40px 70px;
    color: #fff;

    z-index: 2;


    @media (max-width: 1200px) {
      min-height: 350px;
    }

    @media(max-width: 900px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      min-height: 200px;
      padding: 40px 20px;
    }

    &.small {
      min-height: 250px;

      @media (max-width: 768px) {
        min-height: 100px;
      }
    }
  }

  .hero-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin: 0 25px;

      @media only screen and (max-width: 767px) {
        max-width: 100px;
      }
    }
  }

  .hero-title {
    width: 100%;
    max-width: 950px;
    font-style: normal;
    font-weight: 500;
    font-size: 41px;
    line-height: 45px;
    text-align: center;

    margin-bottom: 0;

    @media only screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 1.2;
    }
  }

  .hero-subtitle {
    width: 100%;
    max-width: 730px;
    font-style: normal;
    font-weight: 200;
    font-weight: 500;
    font-size: 21px;
    line-height: 1;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 25px;
      line-height: 1.2;
    }
  }

  .hero-text {
    width: 100%;
    max-width: 780px;
    // margin: 20px 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 15px;
    }

    strong em {
      font-size: 26px;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  .hero-play {
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.1) translateZ(0);
      backface-visibility: hidden;
    }
  }

  .hero-badge {
    position: absolute;
    bottom: 50px;
    right: 50px;
    width: 100%;
    max-width: 65px;
    height: auto;

    z-index: 5;

    @media (max-width: 630px) {
      max-width: 45px;
      top: auto;
      bottom: 25px;
      right: 50%;
      transform: translate(50%, 0);
    }
  }

  .site-btn {
    width: auto;
    max-width: 300px;
    margin: 20px 0 0;

    @media (max-width: 630px) {
      max-width: none;
    }
  }

  .social-links {
    display: none;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 70px;

    a {
      font-size: 24px;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media(max-width: 900px) {
      display: none;
    }
  }
}

// --------------------------------------------------
// Blocks Arguments001
// --------------------------------------------------

.arguments001 {
  @extend .site-block;
  padding: 50px 0 150px;

  @media (max-width: 767px) {
    padding: 0;
  }

  .arguments001-wrapper {
    @extend .site-max-width;
    max-width: 1190px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .grid-item {
      width: 50%;
      text-align: center;
      padding: 0 40px;
      border-right: 1px solid #959595;

      &:last-child {
        border-right: 0;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 40px 20px;
        border-right: 0;
        border-bottom: 1px solid #959595;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .grid-item-stats {
      margin-bottom: 35px;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 31px;
      text-align: center;
      color: $dark;
    }

    .grid-item-btn {
      @extend .site-btn;
      padding: 10px 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $white;
      background-color: $dark;
      border: 1px solid $dark;
    }
  }
}

// --------------------------------------------------
// Blocks grid004
// --------------------------------------------------

.grid004 {

  .grid004-wrapper {
    @extend .site-max-width;
  }

  .grid004-heading {
    text-align: center;
    font-size: 48px;
    font-weight: 400;
  }

  .grid004-container {
    display: flex;
    flex-wrap: wrap;

    .grid004-row {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      align-self: flex-start;

      @media(max-width: 1024px) {
        width: 100%;
      }

      .grid004-article {
        margin: 15px;
        padding: 15px;
        width: calc(50% - (15px * 2));
        background-size: cover;
        background-position: center;

        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, .45);
          z-index: 1;
        }

        @media(max-width: 500px) {
          width: 100%;
        }

        .grid004-article-heading {

        }

        .regular-text {
          color: #fff;
        }

        .article-content-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff;
          z-index: 2;
        }

        &.article-small {

        }

        &.article-large {
          width: 100%;
          min-height: 500px;
          text-align: center;

          @media(max-width: 500px) {
            min-height: 0;
            text-align: left;
          }

          .article-content-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Blocks Cta001
// --------------------------------------------------

.cta001 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta001-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta001-heading {
      margin-top: 0;
      text-align: center;
      font-size: 48px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .cta001-text {
      max-width: 920px;
      height: 90px;
      margin: 0 auto;
      overflow: hidden;
      line-height: 2;
      text-align: center;
      font-weight: 400;
    }

    .cta001-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn-alternate {
      text-align: center;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

// --------------------------------------------------
// Blocks Cta002
// --------------------------------------------------

.cta002 {
  display: flex;
  @extend .site-block;;

  @media all and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 60px;
  }

  &.invert {
    .cta002-img {
      order: 2;
      padding-right: 0;
      padding-left: 40px;

      @media all and (max-width: 1024px) {
        padding-left: 20px;
      }

      @media all and (max-width: 767px) {
        order: 1;

        img {
          margin-left: auto;
        }
      }
    }

    .cta002-desc {
      order: 1;
      justify-content: flex-end;

      @media all and (max-width: 767px) {
        order: 2;
      }

      .cta002-wrap {
        text-align: right;

        @media all and (max-width: 767px) {
          padding: 0 25px 0 0;
        }
      }

      .cta002-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          justify-content: flex-end;

          i {
            order: 2;
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }

  .cta002-desc {
    align-self: center;
    margin-left: 50px;

    @media(max-width: 767px) {
      // @extend .site-max-width
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .cta002-desc-wrap {
    max-width: 580px;
  }

  .cta002-title {
    font-size: 26px;
    font-weight: 500;
  }

  .cta002-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    .cta002-list-items {
      display: flex;


      img {
        width: 32px;
        margin-right: 20px;
        align-self: center;
      }

      .list-items-text {
        margin-bottom: 0;
        align-self: center;
        line-height: 1.6
      }
    }
  }

  .cta002-img {
    width: 50%;
    padding-right: 40px;
    align-self: center;

    img {
      width: 100%;
    }

    @media all and (max-width: 1024px) {
      padding-right: 20px;
    }

    @media all and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
  }
}

// --------------------------------------------------
// Blocks cta003
// --------------------------------------------------

.cta003 {
  position: relative;
  text-align: center;
  padding: 0;
  position: relative;
  background-color: #C60C30;
  background-size: 65% auto;

  .cta003-wrapper {
    @extend .site-max-width;
    max-width: 1400px;
    padding: 100px 20px;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 1023px) {
      padding: 60px 20px;
    }

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    // @extend .site-max-width;
    // @extend .site-block;

    // .cta003-container{
    //   position: relative;
    //   padding: 100px 0;
    //   background: url('/img/site/logo_white_cropped.svg') no-repeat bottom right / contain;
    //   background-color: #C20E30;
    //   background-size: 65% auto;
    //
    //   @media(max-width: 599px){
    //     padding: 30px 30px;
    //     background-size: 100% auto;
    //   }
    // }

    .cta003-heading {
      margin: 0 auto;
      margin-bottom: 35px;
      font-size: 26px;
      line-height: 31px;
      font-weight: 400;
      max-width: 420px;
      color: $white;

      @media(max-width: 599px) {
        font-size: 22px;
      }
    }

    .cta003-logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      max-width: 135px;
    }

    .cta003-phone {
      color: $white;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      margin-right: 50px;
    }

    .cta003-mail {
      color: $white;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
    }

    .cta003-text {
      display: flex;
      flex-direction: column;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 42px;
      line-height: 62px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $white;

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 40px;
      }

      p {
        margin: 0;
        width: 100%;
      }
    }

    .site-btn {
      margin-top: 30px;
      font-weight: 500;
      font-size: 12px;
      padding: 15px 30px;
      border: none;
      border-radius: 3px;
      background-color: $white;
    }
  }
}


// --------------------------------------------------
// Blocks Cta004
// --------------------------------------------------

.cta004 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta004-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta004-heading {
      margin-top: 0;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
    }

    .cta004-text {
      margin: 0 auto;
      line-height: 1.7;
      text-align: center;
      font-weight: 400;
    }

    .cta004-content-container {
      max-width: 547px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .cta004-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn {
      margin-top: 30px;
      padding: 15px 20px;
      background-color: rgb(63, 162, 247);
      font-size: 16px;
      border: none;
      border-radius: 3px;
    }
  }
}

// --------------------------------------------------
// Intro 001
// --------------------------------------------------

.block-intro001 {
  @extend .site-max-width;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 25px;
  margin-bottom: 25px;

  @media (max-width: 460px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .intro-label {
    display: inline-block;
    padding: 8px 15px;
    background: $colorAccent3;
    color: #000;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s;
    transition-duration: .3s;
  }

  .intro-title {
    @extend .big-title;

    width: 100%;
    max-width: 970px;
    font-weight: 400;
    color: #000;

    strong {
      font-weight: 800;
    }
  }

  .intro-subtitle {
    @extend .medium-title;
    width: 100%;
    max-width: 955px;
    color: #000;
    font-size: 18px;
  }

  .intro-content {
    @extend .regular-text;

    width: 100%;
    max-width: 750px;
    margin: 0 auto 10px auto;
    padding: 0;
    text-align: center;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    p {
      a {
        display: inline-block;
        color: #000;
        border-bottom: 1px solid rgba(0, 0, 0, .15);
      }

      strong {
        color: #000;
      }
    }

    ul,
    ul li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  // .site-btn {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;

  //   &:hover {
  //     background: #fff;
  //     color: #000;
  //     border-color: #fff;
  //   }

  //   span {
  //     display: inline-block;
  //     padding-bottom: 5px;
  //     border-bottom: 1px solid rgba(0, 0, 0, .20);
  //   }

  //   img {
  //     margin-left: 10px;
  //   }
  // }
}

// --------------------------------------------------
// Intro 002
// --------------------------------------------------

.block-intro002 {
  position: relative;
  display: flex;
  flex-direction: column;

  z-index: 1;

  width: 100%;
  padding: 45px 0;
  background: $colorAccent2;
  text-align: center;

  @media (max-width: 1299px) {
    padding: 75px 0;
  }

  @media (max-width: 1100px) {
    padding: 55px 0;
  }

  .intro-wrapper {
    @extend .site-max-width;
  }

  .intro-title {
    @extend .big-title;

    max-width: 1024px;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $colorAccent2;
  }

  .intro-separator {
    display: block;
    width: 100%;
    max-width: 660px;
    margin: 20px auto;
  }

  .intro-content {
    @extend .regular-text;

    width: 100%;
    max-width: 775px;
    margin: 0 auto 10px auto;
    padding: 0;
    text-align: center;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    p {
      color: rgba(255, 255, 255, .65);

      a {
        color: $colorAccent1;
      }
    }

    ul,
    ul li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  .intro-btn {
    @extend .site-btn;

    width: 100%;
    max-width: 200px;

    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.2em;
    font-weight: 400;
  }
}

// --------------------------------------------------
// Panels Row 001
// --------------------------------------------------

.block-panelsRow001 {
  @extend .site-max-width;

  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 75px;
  background: #fff;
  text-align: center;

  @media (max-width: 1100px) {
    padding: 55px 0;
  }

  @media (max-width: 699px) {
    padding: 25px 0;
  }

  .panel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    background: $colorAccent2;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .panel-left-col {
      width: 100%;
      max-width: 325px;
      padding: 45px 25px;
      padding-right: 0;
      text-align: center;

      @media (max-width: 699px) {
        padding: 25px 20px;
        padding-bottom: 0;
      }

      h3 {
        margin: 0;
        padding: 0;

        font-size: 22px;
        font-weight: 400;
        text-align: right;
        color: $colorTextLight;
        letter-spacing: 0.052em;
        text-transform: uppercase;

        @media (max-width: 1100px) {
          text-align: center;
        }
      }

      img {
        display: inline-block;
        margin: 0;
      }
    }

    .panel-right-col {
      width: 100%;
      padding: 45px 25px;
      margin: 0;
      text-align: left;
      color: $colorTextLight;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0;

      p {
        margin: 0 0 15px 0;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .panel-logo {
      display: block;
      width: 70%;
    }

    &.panel2 {
      background: #fff;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.panel3 {
      background: $grey;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.white {
      background: #fff;
      border-top: 2px solid rgba(0, 0, 0, .10);

      &:first-child {
        border-top: none;
      }

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 001
// --------------------------------------------------

.block-teamGrid001 {
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  text-align: center;

  max-width: 1110px;
  margin: 0 auto;

  padding: 0;
  // margin-bottom: 140px;


  .big-title {
    font-family: $fontTitle;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: $dark;
    margin-bottom: 90px;
  }

  .regular-text {
    max-width: 1125px;
    padding-left: 20px;
    padding-right: 20px;
    color: $colorTextDark;

    p {
      margin-bottom: 75px;

      @media (max-width: 1100px) {
        margin-bottom: 55px;
      }

      @media (max-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .team-grid-cards {
    @extend .site-max-width;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    text-align: left;

    @media (max-width: 1100px) {
      padding: 0 20px;
    }

    .regular-text,
    h3 {
      font-size: 14px;
      color: $dark;
      font-weight: 400;
      margin: 0 0 20px 0;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .regular-text {
      p {
        padding: 0;
        margin: 0;
      }
    }

    .team-card {
      position: relative;
      display: block;
      width: 100%;
      max-width: 31.5%;
      margin-bottom: 30px;
      background: #fff;
      border-radius: 3px;

      overflow: hidden;

      transition: all .3s ease-out;

      &:hover {
        transform: scale(1.03);
      }

      &:nth-child(n+3) {
        margin-bottom: 0;
      }

      @media (max-width: 1100px) {
        max-width: 48%;
      }

      @media (max-width: 699px) {
        max-width: 100%;
        margin-bottom: 20px;

        &:nth-child(n+3) {
          margin-bottom: 20px;
        }
      }

      .team-img,
      .blog-item-image {
        display: block;
        width: 101%;
        max-height: 500px;
      }

      .content {
        padding: 20px 20px;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-image: linear-gradient(transparent, rgba($dark, .4));

        .team-name {
          margin: 0;
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: normal;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $white;
        }

        .team-subtitle {
          margin: 5px 0;
          font-family: $fontText;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: $white;
        }

        .team-cta {
          margin: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 19px;
          color: rgba(255, 255, 255, .7);
        }
      }
    }
  }

  .team-foot-text {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    font-size: 30px;
    font-weight: 200;
    line-height: 2;
    letter-spacing: -0.025em;
    color: #fff;

    @media (max-width: 699px) {
      font-size: 18px;
      line-height: 1.4;
      padding-bottom: 40px;
      margin-top: -40px;

      br {
        display: none;
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid002 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .teamGrid002-wrapper {
    @extend .site-max-width;

    .teamGrid002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .teamGrid002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .teamGrid002-grid-items {
      width: calc(33.33% - (10px * 2));
      margin: 10px;
      text-align: center;

      &:first-child, &:nth-child(3n+1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      @media(max-width: 650px) {
        width: 100%;
      }

      .grid-items-image {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .grid-items-heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .grid-items-position {
        min-height: 36px;
        margin-top: 10px;
        font-size: 14px;
        color: #90A0B0;

        @media(max-width: 650px) {
          min-height: 0;
          margin-bottom: 0;
        }
      }

      .regular-text {
        @media(max-width: 650px) {
          font-size: 16px;

          p {
            margin: 5px;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid003 {
  @extend .site-block;

  .teamGrid003-wrapper {
    @extend .site-max-width;

    .teamGrid003-carousel {
      width: 100%;
    }

    .flickity-viewport {
      height: 500px;
    }

    .teamGrid003-carousel-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    .teamGrid003-image-wrapper {
      width: calc(50% - (40px * 2));
      margin-right: 40px;
      background-color: rgba(63, 162, 247, .2);

      @media(max-width: 1380px) {
        margin-right: 20px;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .teamGrid003-text-wrapper {
      width: 50%;
      margin-left: 40px;
      padding: 0 30px;

      @media(max-width: 1380px) {
        margin-left: 20px;
        padding: 0;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;

        .teamGrid003-member-jobTitle {
          margin: 0 0 20px 0;
        }
      }

      .teamGrid003-member-heading {
        margin: 0 0 10px 0;
        font-weight: 400;
        font-size: 52px;
        line-height: 58px;
      }

      .teamGrid003-member-jobTitle {
        margin: 0 0 20px 0;
      }

      .teamGrid003-member-bio {
        line-height: 1.8;
      }
    }
  }

  .teamGrid003-sliderNav {
    margin-top: 60px;
    background-color: rgba(63, 162, 247, .2);

    .teamGrid003-sliderNav-wrapper {
      @extend .site-max-width;
      position: relative; //    padding: 30px 0;

      // @media(max-width: 600px){
      //   padding: 30px 0;
      // }
    }

    .teamGrid003-sliderNav-carousel {
      position: relative;
    }

    .flickity-viewport {
      max-height: 160px;
      top: -13px;
      padding-top: 15px;
    }

    .is-selected-arrow {
      position: absolute;
      top: -7px;
      left: 176px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(63, 162, 247, .2);
    }

    .slider-item {
      width: 16.667%;
      padding: 20px 60px;

      @media(max-width: 1130px) {
        width: 20%;
      }

      @media(max-width: 990px) {
        width: 25%;
        padding: 20px 40px;
      }

      @media(max-width: 600px) {
        width: 33.33%;
        padding: 10px 30px;
      }

      &.is-selected {
        &:before {
          content: '';
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -8px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 13px 13px 13px;
          border-color: transparent transparent rgba(63, 162, 247, .2) transparent;
        }
      }

      img {
        width: 100%;
        border-radius: 100%;
      }
    }
  }
}

// --------------------------------------------------
// Block Grid002
// --------------------------------------------------

.grid002 {
  @extend .site-block;;
  background-color: #F2F2F2;

  .grid002-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-direction: column;

    @media (max-width: 1199px) {
      flex-flow: row wrap;
    }

    @media (max-width: 767px) {
      display: block;
      padding-bottom: 0;
    }

    .grid002-title {
      @extend .medium-title;
      color: #19233e;
      text-transform: none;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 1.3;

      @media (max-width: 767px) {
        min-height: inherit;
      }

      @media (max-width: 499px) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .grid002-desc {
      @extend .regular-text;
      width: 100%;
      max-width: 375px;
      min-height: 165px;
      margin: 0 auto;
      font-family: $fontTextLight;
      font-weight: 200;
      font-size: 16px;
      line-height: 1.5;

      p {
        font-size: 16px;
        font-family: $fontText;
        line-height: 1.7;
        color: #959CA2;
        font-weight: 400;
        letter-spacing: 0;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        min-height: inherit;
        padding-bottom: 20px;
      }
    }
  }

}

.grid002-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    box-shadow: none;
  }

  .grid002-row-img {
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -24px;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;
      border-bottom: 20px solid $white;
    }

    .grid002-img {
      display: block;
      width: 100%;
    }
  }

  .grid002-row-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 60px;
    background-color: #fff;
    min-height: 320px;

    @media (max-width: 1699px) {
      padding: 20px 20px 30px 20px;
    }

    @media (max-width: 1380px) {
      padding: 20px 10px 0 10px;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    .grid002-desc {
      p {
        margin-bottom: 0;
      }
    }
  }

  .grid002-row-element {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    text-align: center;

    @media (max-width: 1199px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      float: left;
      width: 100%;
      padding: 0 20px;

      .grid002-desc {
        min-height: 0;
      }

      .grid002-title {
        min-height: 0;
      }

      &:last-child {
        margin-bottom: 45px;
      }
    }

    @media (max-width: 349px) {
      width: calc(90% - 10px);
    }

    &:nth-child(4),
    &:nth-child(6) {
      @media (min-width: 768px) and (max-width: 1199px) {
        .grid002-row-img {
          order: 2;
          width: 100%;

          &:before {
            top: inherit;
            bottom: 0;
            transform: inherit;
          }
        }

        .grid002-row-content {
          order: 1;

          @media(max-width: 1199px) {
            border-bottom: 0;
          }
        }
      }
    }

    &:nth-child(2) {
      @media (min-width: 768px) {

        .grid002-row-img {
          order: 2;
          width: 100%;
          @media (max-width: 767px) {
            order: 1;
          }

          &:before {
            top: -1px;
            transform: rotate(180deg);
          }
        }

        .grid002-row-content {
          order: 1;
          border-bottom: 0;
        }
      }
    }

    &:nth-child(5) {
      @media (min-width: 1200px) {

        .grid002-row-img {
          order: 2;
          width: 100%;
          @media (max-width: 767px) {
            order: 1;
          }

          &:before {
            top: -1px;
            transform: rotate(180deg);
          }
        }

        .grid002-row-content {
          order: 1;
          border-bottom: 0;
        }
      }
    }
  }
}

// --------------------------------------------------
// Block Grid003
// --------------------------------------------------

.grid003 {
  @extend .site-block;
  padding: 0;

  .grid003-wrapper {
    @extend .site-max-width;
    max-width: 1110px;
    padding: 0;
  }

  .grid003-grid {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      display: block;
      margin-top: 20px;
    }

    @media(max-width: 1024px) {
      justify-content: center;
      flex-wrap: wrap;
    }

    /* enable Flickity by default */
    &:after {
      content: '';
      display: none; /* hide :after */
    }

    @media screen and (max-width: 767px) {
      /* disable Flickity for large devices */
      &:after {
        content: 'flickity';
      }
    }

    .grid003-grid-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      text-align: center;
      border-radius: 3px;
      margin-bottom: 100px;
      max-width: 205px;

      &.with-logo {
        transform: translateY(-20%);
        background-color: $white;
        z-index: 3;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
          transform: translateY(0);
        }
      }

      &.is-bordered {
        border-bottom: 3px solid $colorAccent2;
        box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.20);

        @media screen and (max-width: 767px) {
          border: 2px solid $colorAccent2;
          box-shadow: none;
        }
      }

      &.col-3 {
        width: calc(33.33% - (15px * 3));

        @media(min-width: 591px) {
          &:first-child, &:nth-child(3n+1) {
            padding-left: 0;
            margin-left: 0;
          }

          &:last-child, &:nth-child(3n) {
            padding-right: 0;
            margin-right: 0;
          }
        }

        @media screen and (max-width: 590px) and (min-width: 441px) {
          width: calc(50% - (15px * 2));
          // padding: 0;

          &:nth-child(odd) {
            padding-left: 0;
            margin-left: 0;
          }

          &:nth-child(even) {
            padding-right: 0;
            margin-right: 0;
          }
        }

        @media(max-width: 440px) {
          width: calc(100%);
          padding-right: 0;
          padding-left: 0;
          margin-right: 0;
          margin-left: 0;
        }
      }

      &.col-4 {
        width: calc(25% - (15px * 4));

        &:first-child, &:nth-child(4n+1) {
          margin-left: 0;
        }

        &:last-child, &:nth-child(4n) {
          margin-right: 0;
        }

        @media screen and (max-width: 1024px) and (min-width: 771px) {
          width: calc(33.33% - (15px * 3));

          &:first-child, &:nth-child(3n+1) {
            margin-left: 0 !important;
            margin-right: 15px;
          }

          &:nth-child(4n+1) {
            margin: 15px;
          }

          &:last-child, &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media screen and (max-width: 770px) and (min-width: 441px) {
          width: calc(50% - (15px * 2));

          &:nth-child(odd) {
            padding-left: 0;
            margin-left: 0;
          }

          &:nth-child(even) {
            padding-right: 0;
            margin-right: 0;
          }
        }

        @media(max-width: 440px) {
          width: 100%;
          padding-right: 0;
          padding-left: 0;
          margin-right: 0;
          margin-left: 0;
        }
      }

      &.col-5 {
        // width: calc(20% - (15px * 5));
        width: calc(98% / 5);

        @media screen and (max-width: 1024px) {
          width: calc(98% / 3);
          margin: 2%;
        }


        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0;
          min-height: 220px;
        }
      }

      @media(max-width: 1291px) {
        width: calc(33.33% - (15px * 2));
        justify-content: flex-start;
      }

      @media(max-width: 800px) {
        width: calc(50% - (15px * 2));
      }

      @media(max-width: 599px) {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        // border-radius: 100%;
      }

      .logo-wrapper {
        padding-top: 30px;
        padding-bottom: 25px;

        .logo {
          display: block;
          width: auto;
          height: 50px;
        }
      }

      .grid-items-heading {
        height: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: normal;
        text-align: center;
        margin: 0;
        padding: 30px 10px;

      }

      .regular-text {
        height: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: normal;
        text-align: center;

        margin: 0;
        padding: 0 10px;

        p {
          margin: 0;
          padding: 0 0 30px 0;
        }

        strong {
          font-family: $fontText;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: normal;
          text-align: center;
          color: $dark;
        }
      }

      .site-btn {
        margin-top: 10px;
        padding: 10px 15px;
        font-size: 15px;
        font-weight: 500;
        background-color: rgb(63, 162, 247);
        border: none;
        border-radius: 5px;
      }
    }
  }
}

// --------------------------------------------------
// Timeline 001
// --------------------------------------------------

.block-timeline001 {
  padding: 100px 20px;
  margin: 0;

  @media (max-width: 1024px) {
    padding: 60px 20px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .timeline-wrapper {
    background-color: white;
    color: $dark;
  }

  .timeline-content {
    max-width: 1100px;
    display: block;
    width: 100%;
    margin: 0 auto;

    .timeline-content-heading {
      margin: 0 0 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 71px;
      text-align: center;

      @media (max-width: 1024px) {
        margin: 0 0 40px;
      }

      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .timeline-date {
      @extend .main-text;
    }

    .timeline-number {
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #C60C30;
    }

    .timeline-title {
      @extend .title4;
      margin: 0 0 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: $dark;

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
    }

    .timeline-desc {
      @extend .main-text;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $dark;

      p {
        color: $dark;
      }

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
      @media(max-width: 1050px) {
        font-size: 16px;
      }
    }
  }

  .timeline-row {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 70px;

    &:last-child {
      padding-bottom: 0;
    }

    @media (max-width: 1023px) {
      padding-bottom: 40px;
    }

    @media (max-width: 699px) {
      flex-direction: column;
      padding-left: 60px;
      padding-bottom: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 4px;
      height: 100%;
      margin-left: -1.5px;
      opacity: .1;
      background-color: $dark;

      @media (max-width: 699px) {
        left: 10px;
        margin-left: 2.5px;
      }
    }

    &:first-of-type {
      &:before {
        top: calc(50% - 35px);
        height: calc(50% + 35px);
      }
    }

    &:last-of-type {
      &:before {
        height: calc(50% - 35px);
      }
    }

    .timeline-row-img {
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .timeline-row-dot {
      position: relative;
      margin: 0 45px;

      @media (max-width: 699px) {
        position: absolute;
        left: 0;
        top: calc(50% - 80px);
        width: 24px;
        height: 24px;
        border-width: 3px;
        margin: 0;
      }
    }

    .timeline-row-content {
      color: $dark;
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
      }
    }

    &:nth-child(even) {
      .timeline-row-img {
        order: 3;

        @media (max-width: 699px) {
          order: 2;
        }
      }

      .timeline-row-dot {
        order: 2;

        @media (max-width: 699px) {
          order: 1;
        }
      }

      .timeline-row-content {
        order: 1;
        text-align: left;

        @media (max-width: 699px) {
          order: 3;
          text-align: left;
        }
      }
    }
  }

  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Loader 2 */
  .loader-container {
    position: relative;
    width: 100%;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0px auto;
    width: 200px;
  }

  .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes color {
    100%, 0% {
      stroke: $dark;
    }
    40% {
      stroke: $dark;
    }
    66% {
      stroke: $dark;
    }
    80%, 90% {
      stroke: $dark;
    }
  }

  .beacon-container {
    position: relative;
  }

  .beacon {
    width: 30px;
    height: 30px;
    background-color: $colorAccent3;
    border-radius: 50%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

    &:after,
    &:before {
      position: absolute;
      display: block;
      background-color: transparent;
      transform-origin: 50% 50%;
    }

    &:after,
    &:before {
      content: '';
      top: -1px;
      left: -1px;
      border: 1px solid $colorAccent3;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transform: scale(1.3, 1.3);
    }

    &:before {
      animation: pulse 2s infinite;
    }

    &:after {
      animation: pulse 2s infinite .7s;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-moz-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-o-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }


}

// --------------------------------------------------
// Block Items SLider
// --------------------------------------------------

.block-slider001 {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 75px 30px 0 30px;
  margin-bottom: 0;
  background: #fff;
  text-align: center;

  @media (max-width: 1100px) {
    padding: 55px 20px;
  }

  .regular-text {
    color: $colorTextDark;
    max-width: 1125px;

    p {
      margin-bottom: 75px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .item-slider-containter {
    position: relative;
    width: 100%;
    max-width: 960px;
    padding: 45px 0;
    margin: 0 auto;
    // background: $grey;

    .items-slider-title {
      margin: 15px 0 10px 0;
      text-align: left;
      text-transform: uppercase;
      color: $colorTextDark;
      font-weight: 400;
      letter-spacing: 0.055em;

      @media (max-width: 699px) {
        text-align: center;
      }
    }

    .items-slider {
      position: relative;
      width: 100%;
      padding: 40px 55px;
      background: $grey;

      @media (max-width: 699px) {
        padding: 20px;
      }

      .slide {
        width: 32%;
        min-height: 200px;
        padding: 25px;
        background: $colorAccent2;
        margin: 0 1%;

        @media (max-width: 1100px) {
          width: 49%;
        }

        @media (max-width: 699px) {
          width: 100%;
          padding: 20px;
        }

        h3,
        p,
        ul,
        ul li {
          color: #fff;
          text-align: center;
        }

        h3 {
          min-height: 32px;
          font-size: 14px;
          letter-spacing: 0.095em;
          font-weight: 400;
        }

        ul,
        .content {
          min-height: 72px;
        }

        p
        ul,
        ul li {
          color: #fff;
          text-align: left;
          line-height: 1;
          margin: 0;
          padding: 2px 0;
          font-weight: 500;
          letter-spacing: 0.055em;
          font-size: 14px;
        }
      }
    }

    .flickity-page-dots {
      left: 0;
    }

    .flickity-prev-next-button.previous {
      left: -70px;

      @media (max-width: 1100px) {
        left: -20px;
        background: $colorAccent2;
        color: #fff;

        .flickity-button-icon {
          fill: #fff;
        }
      }

      @media (max-width: 699px) {
        // transform: scale(.70);
        display: none;
      }
    }

    .flickity-prev-next-button.next {
      right: -70px;

      @media (max-width: 1100px) {
        right: -20px;
        background: $colorAccent2;
        color: #fff;

        .flickity-button-icon {
          fill: #fff;
        }
      }

      @media (max-width: 699px) {
        // transform: scale(.70);
        display: none;
      }
    }
  }
}

// --------------------------------------------------
// Block Slider002
// --------------------------------------------------

.slider002 {
  @extend .site-block;;

  .slider002-heading {
    text-align: center;
    font-size: 48px;
    font-weight: 400;
  }

  .carousel-items {
    opacity: .5;
  }

  .is-selected {
    opacity: 1;
  }
}

// --------------------------------------------------
// Block Slider003
// --------------------------------------------------

.slider003 {
  @extend .site-block;
  margin-bottom: 0;
  padding: 100px 0;
  background-color: $dark;

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  &.is-carousel {

    .slider-main-title {
      margin: 0 0 40px;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 71px;
      text-align: center;
      padding: 0 20px;
      color: $white;

      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .slider-main-title {
    margin: 0 0 40px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 71px;
    text-align: center;
    padding: 0 20px;
    color: #fff;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .slider-main-text {
    display: block;
    margin: 0 auto 70px;
    padding: 0 20px;
    max-width: 350px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $white;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .site-btn {
    display: none;
  }

  .slider003-wrapper {
    @extend .site-max-width;
    max-width: calc(1110px + 45px * 2);
    display: flex;
    text-align: center;

    @media (max-width: 1249px) {
      padding: 0 65px 35px;
    }

    @media (max-width: 1024px) {
      padding: 0 25px 35px;
    }

    @media (max-width: 899px) {
      padding: 0 0 35px;
      margin: -20px auto 0 auto;
    }

    @media (max-width: 460px) {
      margin: -20px auto -20px auto;
      padding-bottom: 0;
    }

    &.slider-rotate {
      &:after {
        content: 'flickity';
        display: none; /* hide :after */
      }

      &.force-slider {
        &:after {
          content: 'flickity';
          display: none; /* hide :after */
        }
      }
    }

    .flickity-viewport {
      width: 100%;
      overflow: hidden;
      min-height: 340px;
      padding: 20px 0;
    }

    .flickity-prev-next-button{
      top: 38%;
      width: 44px;
      height: 44px;
      background-color: $white;
      transition: .5s;

      &:hover{
        transform: translateY(-50%) scale(1.2);
      }
    }
    .flickity-prev-next-button.previous:before,
    .flickity-prev-next-button.next:before {
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: $dark;

      border-radius: 100%;
      // padding: 10px;
    }

    .previous:before{
      content: "\E823";
    }

    .next:before{
      content: "\E82B";
    }

    .value-icon {
      display: block;
      color: $colorAccent2;
      font-size: 40px;
      margin-bottom: 15px;
    }

    .value-title {
      // @extend .title4;
      // margin: 0 0 30px ;
      display: flex;
      // display: block;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      align-items: center;
      text-align: center;

      // min-height: 55px;
    }

    .value-desc {
      @extend .main-text;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      font-size: 17px;
      line-height: 1.4;

      min-height: 85px;

      @media all and (max-width: 1024px) {
        font-size: 16px;
        max-width: 100%;
      }
    }


    .value-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(25% - 3px);
      min-height: 215px;
      padding: 20px;
      border-bottom: 4px solid $colorAccent2;
      transform: scale(.9);
      transition: transform ease-out .2s, box-shadow ease-out .2s;
      background-color: $white;
      border-radius: 3px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .1);
      margin-bottom: 15px;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -2px;
        width: calc(100% + 4px);
        height: 3px;
        background-color: transparent;
      }

      &:first-child{
        img{
          margin-bottom: 27px !important;
        }
      }

      @media all and (max-width: 899px) {
        width: 40%;
      }

      @media all and (max-width: 767px) {
        width: 50%;
      }

      @media all and (max-width: 699px) {
        width: 60%;
      }

      @media all and (max-width: 499px) {
        width: 100%;
      }

      // &.is-selected {
      //   position: relative;
      //   z-index: 2;
      //   transform: scale(1.15);
      // }
    }
  }

  &.no-slider {
    background: #000;
    padding-bottom: 45px;

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .slider003-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .slider-main-title {
      display: block;
    }

    .site-btn {
      display: inline-block;
    }

    .site-btn-holder {
      text-align: center;
    }

    .slider003-wrapper .value-card {
      padding-top: 0;
      background: transparent;
      border-bottom: none;

      img {
        margin-bottom: 30px;
      }

      .value-title {
        margin: 0 0 30px;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        display: block;
        align-items: center;
        text-align: center;
        min-height: 0;
      }

      .value-title,
      .value-desc {
        color: #fff;
      }
    }
  }

  &.cols-4 {

    .slider003-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .slider003-wrapper .value-card {
      display: inline-block;
      width: 25%;
      padding: 20px 0;

      @media all and (max-width: 899px) {
        width: 40%;
      }

      @media all and (max-width: 767px) {
        width: 50%;
      }

      @media all and (max-width: 699px) {
        width: 60%;
      }

      @media all and (max-width: 499px) {
        width: 100%;
      }

      .value-title {
        // min-height: 50px;
      }
    }
  }
}

// --------------------------------------------------
// Block Slider004
// --------------------------------------------------

.slider004 {
  @extend .site-block;;

  .slider004-wrapper {
    @extend .site-max-width;
  }

  .slider004-content-title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 4px;
  }

  .slider004-content-desc {
    width: 100%;
    max-width: 570px;
    line-height: 1.8;
    letter-spacing: 0.05em;

    @media (max-width: 767px) {
      margin: auto;
      margin-bottom: 15px;
    }
  }

  .flickity-viewport {
    min-height: 415px !important;
  }


  .slider004-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      padding: 0 40px;
    }

    .slider004-img {
      width: 50%;
      min-width: 100px;
      display: flex;
      align-items: center;

      img {
        width: 60%;
        display: block;
        margin: 0 auto;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }
    }

    .slider004-content {
      width: 50%;
      padding: 0 15px;

      a.site-btn {
        color: $white;
        margin-top: 8px;

        &:hover {
          color: #fff;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin: 20px 0;
      }

      .flickity-button {
        .flickity-prev-next-button.previous::before,
        .flickity-prev-next-button.next::before {
          top: -30px;
        }
      }
    }
  }

  .flickity-page-dots {
    bottom: 0;
    left: 0;
  }
}

// --------------------------------------------------
// Block Split002
// --------------------------------------------------

.split002 {
  @extend .site-block;;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-top: 1px solid black;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .split002-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-wrap: wrap;
  }

  .split002-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .split002-content-heading {
      margin-top: 0;
    }

  }

  .split002-text-wrapper, .split002-image-wrapper {
    width: 50%;
    padding: 0 70px;

    @media(max-width: 1400px) {
      padding: 0 20px;
    }

    @media(max-width: 880px) {
      width: 100%;
      padding: 0;
    }
  }

  .split002-text-wrapper {
    padding-left: 0;

    @media(max-width: 880px) {
      order: 1;
    }
  }

  .split002-image-wrapper {
    padding-right: 0;

    @media(max-width: 880px) {
      padding-bottom: 40px;
      order: 0;
    }

    img {
      width: 100%;
    }
  }

  .big-content {
    width: 100%;
    padding-top: 40px;

    @media(max-width: 1400px) {
      padding-top: 0;
    }
  }

  .small-content {
    width: 50%;
    margin-top: -40px;
    padding: 0 20px;

    @media(max-width: 1400px) {
      margin-top: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }

    &:nth-child(odd) {
      padding-right: 0;
    }
  }
}

// --------------------------------------------------
// Block Split003
// --------------------------------------------------


.split003 {
  @extend .site-block;
  background-color: $dark;
  padding: 100px 0;

  @media(max-width: 1110px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media(max-width: 1024px) {
    padding: 40px 0 0;
  }

  .split003-wrapper-block {
    // @extend .site-max-width;
    .split003-block-heading {
      margin: 0 0 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 71px;
      text-align: center;
      color: $white;

      @media (max-width: 1023px) {
        padding: 0 20px;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 60px;
      }
    }
  }

  .split003-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 1110px;
    margin: 0 auto;
    background-color: $white;

    @media(max-width: 767px) {
      width: 100%;
      flex-direction: column-reverse;
    }

    &.reverse {
      flex-direction: row-reverse;

      @media(max-width: 767px) {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
  }

  .split003-text-wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    @media(max-width: 767px) {
      width: 100%;
    }

    .split003-content-container {
      text-align: left;
      width: 52%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;

      @media(max-width: 1023px) {
        padding: 40px 20px;
        width: 100%;
      }

      .split003-heading {
        margin-top: 0;
        margin-bottom: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;

        @media(max-width: 500px) {
          font-size: 16px;
        }
      }

      .split003-subheading {
        margin-top: 0;
        margin-bottom: 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      .split003-text {
        // margin-bottom: 36px;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;

        @media(max-width: 599px) {
          font-size: 12px;
          letter-spacing: 0.1px;

          br {
            display: none;
          }
        }

        @media(max-width: 460px) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .site-btn {
        padding: 15px 20px;
        font-size: 16px;
        border: none;
        border-radius: 3px;
      }
    }
  }

  .split003-image-wrapper {
    width: 50%;

    @media(max-width: 767px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.split003-cta {
  @extend .site-block;
  background-color: $colorAccent3;
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 55px;

  @media(max-width: 1110px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }

  @media(max-width: 599px) {
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }

  @media(max-width: 599px) {
    margin-bottom: 0;
  }


  p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    text-align: center;

    @media(max-width: 599px) {
      font-size: 18px;
    }
  }
}

// --------------------------------------------------
// Block Split 004
// --------------------------------------------------

.split004 {
  display: flex;
  padding: 70px 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 560px) {
    padding: 0;
  }

  &.reversed-block {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }

  }

  .block-content-wrapper {
    @extend .site-max-width;
    display: flex;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    align-items: center;

    // opacity: 0;

    @media(max-width: 767px) {
      display: inline-block;
    }

    .gallery-wrapper {
      position: relative;
      // left: 75px;
      width: 60%;

      transition: 1s;

      .gallery-wrapper-form {
        width: 90%;
      }

      @media (max-width: 900px) {
        width: 100%;
      }
      @media(max-width: 767px) {
        left: 0;
        top: 25px;

        img {
          width: 90%;
          margin: 0 auto;
          display: inherit;
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;

        display: block;

        pointer-events: none;
        z-index: 3;
      }

      img {
        width: 100%;
        vertical-align: middle;
      }
    }


    .flickity-button {
      bottom: 0;
      top: auto;

      background: $white;
      border-radius: 0;
      transform: none;

      &.previous {
        padding: 40px 25px 40px 50px;
        right: 75px;
        left: auto;

        @media (min-width: 768px) and (max-width: 1150px) {
          padding: 0 25px 30px 50px;
        }
      }

      &[disabled] {
        display: none;
      }

      svg {
        display: none;
      }
    }

    .gallery-content {
      position: relative;
      padding: 50px 50px;
      width: 40%;
      background-color: $white;
      height: auto;

      transition: 1s;

      @media (max-width: 1024px) {
        padding: 30px;
      }

      @media (max-width: 900px) {
        width: 100%;
        padding: 40px 20px;
      }

      @media(max-width: 767px) {
        left: 0;
        top: -25px;
        width: 90%;
        margin: 0 auto;
      }

      .medium-title {
        display: inline-block;
        color: $colorAccent4;
        font-size: 45px;
        line-height: 48px;
        margin: 0 0 10px;
        font-weight: 600;
        letter-spacing: 0;

        @media(max-width: 1024px) {
          font-size: 25px;
          line-height: 25px;
          margin: 0 0 0px;
        }

        @media(max-width: 767px) {
          font-size: 25px;
          line-height: 28px;
          margin: 0 0 10px;
        }

        &.compact-title {
          // max-width: 255px;
        }
      }

      .small-title {
        color: $grey;
        text-transform: uppercase;
      }

      .regular-text {
        font-family: $fontText;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        color: #959ca2;
        margin: 0 0 30px;
        font-weight: 400;

        @media(max-width: 1024px) {
          // margin: 0 0 -30px;
          line-height: 22px;
        }
      }
    }
  }

  .image-on {
    @media(max-width: 1024px) {
      display: block;
    }

    .gallery-wrapper {
      width: 40%;
      z-index: 2;

      @media (max-width: 1024px) {
        position: static;
        display: block;
        width: 90%;
        margin: 0 auto;
      }
      @media(max-width: 560px) {
        width: 100%;
      }
    }

    .gallery-content {
      width: 60%;
      padding: 50px 50px 50px 200px;
      background-color: $colorAccent1;
      color: $white;

      @media (max-width: 1024px) {
        position: static;
        display: block;
        margin: 0 auto;
        width: 100%;
        padding-left: 90px;
      }

      @media (max-width: 767px) {
        padding: 50px;
      }

      @media (max-width: 560px) {
        padding: 30px;
      }

      .medium-title {
        margin: 0 0 10px 0;
        color: $white;
        font-size: 45px;
        line-height: 48px;

        @media (max-width: 560px) {
          font-size: 25px;
          line-height: 28px;
        }
      }

      .gallery-block-author {
        margin-top: 40px;
      }

      .gallery-author-wrapper {
        display: flex;

        img {
          border-radius: 100%;
        }

      }

      .gallery-author-text-wrapper {
        margin-left: 40px;

        .author-heading {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 26px;
          color: $white;

          @media(max-width: 455px) {
            margin-top: 0;
          }
        }

        .author-text {
          margin: 7px 0;
          height: 64px;
          overflow: hidden;
          color: #959CA2;

          @media(max-width: 455px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .site-btn-alternate {
          color: $colorAccent2;
        }
      }
    }
  }
}

// --------------------------------------------------
// Block Split 005
// --------------------------------------------------

.split005 {
  .split005-wrapper {
  }
}

// --------------------------------------------------
// Block Split 006
// --------------------------------------------------
.split006 {
  @extend .site-block;
  padding: 150px 0 100px;

  @media (max-width: 1024px) {
    padding: 40px 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
  }

  .split006-section-heading{
    margin: 0 0 40px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 71px;
    text-align: center;
    padding: 0 20px;
    color: $colorAccent1;

    @media(max-width: 767px){
      margin-bottom: 0;
      font-size: 32px;
      line-height: 1.2;
    }
  }

  .split006-wrapper {
    @extend .site-max-width;
    position: relative;
    max-width: calc(1110px + 75px * 2);
    // height: 600px;

    background-size: cover;
    background-repeat: no-repeat;

    @media(max-width: 830px){
      // height: 600px;
    }

    @media (max-width: 767px) {
      padding: 40px 20px;
      // height: auto;
    }

    @media(max-width: 600px){
      // height: 400px;
    }
  }

  .flickity-slider{
    transform: translateX(0);
  }

  .flickity-viewport{
    height: 100%;
  }

  .flickity-prev-next-button{
    width: 44px;
    height: 44px;
    background-color: #000;
    transition: .5s;

    @media(max-width: 767px){
      display: none;
    }

    &:hover{
      transform: translateY(-50%) scale(1.2);
    }

    &.previous:before{
      content: "\E823";
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: white;
    }

    &.next:before{
      content: "\E82B";
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: white;
    }
  }
  .flickity-page-dots{
    left: 0;
  }
  .split006-content{
    height: auto;
    width: 100%;
  }

  .split006-block-text {
    position: absolute;
    left: 30px;
    bottom: 15%;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    background-color: rgba(255, 255, 255, .7);

    // @media (max-width: 767px) {
    //   padding: 20px;
    //   background-color: rgba(white, .90);
    // }

    @media(max-width: 767px){
      display: none;
    }
  }

  .split006-block-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img{
      opacity: 0;
      width: 100%;
      max-height: 540px;
    }
  }

  .split006-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 31px;
  }

  .split006-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
}

// --------------------------------------------------
// Block Split 007
// --------------------------------------------------

.split007 {
  @extend .site-block;
  padding: 100px 0;
  background-color: $dark;
  color: $white;

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .split007-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-wrap: wrap;
    max-width: 1260px;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .split007-heading {
    width: 100%;
    margin: 0 0 100px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 71px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 40px;
    }
  }

  .split007-block-text {
    width: 60%;
    padding-right: 90px;

    @media (max-width: 1100px) {
      padding-right: 40px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-right: 0;
    }

    .split007-text {

      strong {
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
      }

      p {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }

    .subHeading-wrapper {
      display: flex;

      .list-subHeading {
        width: 50%;
        font-style: italic;
        font-weight: normal;
        font-size: 24px;
        line-height: 21px;

        &:first-child{
          color: $colorAccent3;
        }

        &:last-child{
          color: $colorAccent2;
        }
      }
    }

    .list-wrapper {
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
    }

    .list-heading {
      margin: 30px 0;
    }

    .list {
      width: 50%;
      margin: 0;
      padding-left: 0;
      padding-right: 45px;

      img {
        width: 20px;
        height: 20px;
        margin: 16px 18px 0 0;
        align-self: flex-start;
        display: block;
      }

      .list-item-text {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
      }
    }

    .list-item-text {
      width: calc(100% - 40px);
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .list-item {
      display: flex;
      flex-wrap: wrap;
      border: none;
    }
  }

  .split007-block-image {
    width: 40%;

    @media (max-width: 767px) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

// --------------------------------------------------
// Block Split 008
// --------------------------------------------------

.split008 {
  @extend .site-block;
  padding: 100px 0;
  background-repeat: no-repeat;
  background-size: 446px;
  background-position-y: 100px;

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .split008-wrapper {
    @extend .site-max-width;
    display: flex;
    max-width: calc(1110px + 45px * 2);


    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 700px) {
      // display: block;
      flex-direction: column;
    }
  }

  .split008-list-wrapper {
    // width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 700px) {
      width: 100%;
      order: 2;
    }
  }

  .split008-video-wrapper {
    margin-left: 20px;
    max-width: 825px;

    @media (max-width: 1023px) {
      margin-left: 0;
      margin-bottom: 40px;
    }

    @media (max-width: 700px) {
      width: 100%;
      margin: 0 0 30px;
    }

    // .icon-play {
    //   position: relative;
    //   top: -195px;
    //   display: block;
    //   margin: 0 auto;
    //   width: 40px;
    //   height: 40px;
    // }
  }

  .split008-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 1023px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .list-element {
      // margin-bottom: 90px;

      @media (max-width: 1023px) {
        margin-bottom: 0;
        width: calc(33.33334% - (40px / 3));
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover{
        transform: scale(1.2);
        transition: transform .5s;
        // background-color: rgba(black, .1);
      }

      &:not(:hover){
        transform: scale(1);
        transition: transform .5s;
      }
    }
  }

  .player-url {
    position: relative;
    display: block;

    .video-image {
      width: 100%;
      transition: ease-out .2s;

      &:hover {
        filter: brightness(50%);
      }
    }
  }

  .player-btn {
    // position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    margin: 0 auto;
    // img{
    //   width: 100%;
    // }

    // .icon-play{
    //
    //   position: absolute;
    //  // top: 50%;
    //  // left: 50%;
    //  // -ms-transform: translate(-50%, -50%);
    //  // transform: translate(-50%, -50%);
    //
    //  img{
    //    width: 50px;
    //    display: block;
    //    margin: auto;
    //  }
    // }
  }

  .list-element {

    @media (max-width: 700px) {
      width: 33.33%;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 80px;
    }

    .list-item-heading {
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */

      text-align: center;

      @media (max-width: 700px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

// --------------------------------------------------
// Block Portfolio001
// --------------------------------------------------

.portfolio001 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .portfolio001-wrapper {
    @extend .site-max-width;
    text-align: center;

    .portfolio001-heading {
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 48px;
      font-weight: 300;
    }

    .portfolio001-carousel-items {
      width: 100%;
      padding: 0 100px;

      @media(max-width: 670px) {
        padding: 0 50px;
      }

      @media(max-width: 530px) {
        padding: 0;
      }

      img {
        width: 100%;
      }

      .portfolio001-carousel-items-heading {
        margin-top: 50px;
      }

      .regular-text {
        p {
          display: inline-block;
          max-width: 770px;
        }
      }
    }

    .flickity-prev-next-button {
      top: 40%;
    }
  }
}

// --------------------------------------------------
// Block pricing001
// --------------------------------------------------

.pricing001 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .pricing001-wrapper {
    @extend .site-max-width;
    text-align: center;

    .pricing001-heading {
      font-size: 48px;
      font-weight: 400;
    }

    .pricing001-grid {
      display: flex;
      flex-wrap: wrap;

      .pricing001-grid-items {
        width: 33.33%;
        padding: 35px 40px;
        border: 2px solid rgba(0, 0, 0, .1);

        &:first-child, &:nth-child(2) {
          border-right: none;
        }

        @media(max-width: 855px) {
          width: 100%;

          &:first-child, &:nth-child(2) {
            border-bottom: none;
            border-right: 2px solid rgba(0, 0, 0, .1);
          }
        }
      }

      .grid-items-header {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        @media(max-width: 855px) {
          padding-bottom: 0;
        }
      }

      .grid-items-heading {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 400;
      }

      .grid-items-shortDescription {
        margin: 0;
        font-size: 14px;
      }

      .grid-items-description {
        margin-bottom: 0;
        font-size: 14px;

        p {
          min-height: 72px;
          margin-bottom: 0;
        }
      }

      .grid-items-price {
        font-weight: 400;
        font-size: 60px;
        margin: 30px 0;
      }

      .grid-items-list {
        padding: 0;
        list-style: none;
      }

      .grid-items-list-items {
        padding: 15px 0;
      }

      .site-btn {
        padding: 10px 35px;
        font-size: 16px;
      }
    }
  }
}

// --------------------------------------------------
// Block pricing002
// --------------------------------------------------

.pricing002 {
  padding: 20px 0;
  color: rgb(63, 162, 247);

  .pricing002-wrapper {
    @extend .site-max-width;

    .pricing002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .pricing002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-items {
      width: calc(50% - (20px * 2));
      margin: 10px;
      padding: 20px;
      border: 1px solid rgba(63, 162, 247, .1);

      @media(max-width: 800px) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      .grid-items-heading {
        display: inline-block;
      }

      .grid-items-pricing {
        display: inline-block;
        float: right;
      }

      .pricing-bar-container {
        position: relative;
        width: 100%;

        &:focus {
          outline: 0;
        }

        .value-bar {
          position: absolute;
          top: 2px;
          width: 35%;
          height: 10px;
          display: block;
          z-index: 1;
          background-color: rgb(63, 162, 247);

          &:focus {
            outline: 0;
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          width: 100%;
          height: 10px;
          position: absolute;
          top: 2px;
          background-color: rgba(63, 162, 247, .1);
          z-index: 5;

          &:focus {
            outline: 0;
          }
        }

        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: rgb(63, 162, 247);
          position: relative;
          z-index: 3;
          cursor: pointer;

          &:focus {
            outline: 0;
          }
        }
      }

      .pricing-bar-values {
        display: flex;
        margin-top: 20px;

        span {
          position: relative;
          width: 20%;
          margin-top: 10px;
          margin-right: 5px;
          text-align: right;

          &:before {
            position: absolute;
            top: -13px;
            content: "|";
            font-size: 10px;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }


    }

    .pricing002-total-container {
      .pricing002-total-price {
        font-size: 38px;
        text-align: center;
      }
    }
  }
}

// --------------------------------------------------
// Split text image
// --------------------------------------------------

.block-split-text-image {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  &.is-left {
    flex-direction: row-reverse;
  }

  .block-content {
    position: relative;
    width: 75%;
    padding: 75px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-collapsed {
      &:after {
        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
        z-index: 2;
      }
    }

    .regular-text {
      // margin-bottom: 75px;


      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      @extend .big-title;
      //color: $dark;
      line-height: 1.4;

      @media only screen and (max-width: 767px) {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
    }

    h3 {
      @extend .big-title;
      color: $dark;
      font-size: 26px;
      line-height: 1.4;
    }

    h4 {
      @extend .big-title;
      color: $dark;
      font-size: 20px;
      line-height: 1.4;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figure {
      padding: 0;
      margin: 0;
    }

    blockquote {
      padding: 0 0 0 25px;
      margin: 25px 0;
      border-left: 4px solid $lighter;
    }

    .read-more {
      display: none;
      position: absolute;
      right: 75px;
      bottom: 25px;
      z-index: 3;
      padding: 5px 0;
      font-size: 14px;
    }
  }

  .block-image {
    width: 25%;

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Block - Grid
// --------------------------------------------------

.block-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 40px;
  align-items: center;
  justify-content: center;
  background: white;
  border-bottom: 1px #eaeaea solid;
  text-align: center;
  margin: 0;

  @media (max-width: 767px) {
    padding: 40px 20px;
    margin: 0;
  }

  .big-title {
    color: black;
    margin: 0 0 50px;
  }

  &.simple {
    padding: 100px 20px;
    margin: 0 auto;
    border-top: none;
    background-repeat: no-repeat;
    background-position-x: 81%;
    background-position-y: 36px;

    @media (max-width: 1024px) {
      padding: 60px 20px;
      border-bottom: 0;
    }

    @media (max-width: 767px) {
      padding: 40px 20px;
      border-bottom: 0;
    }
  }

  .grid-text {
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 767px) {
    }
  }

  .grid-items {
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    text-align: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .grid-item {
    width: calc(33.33334% - 20px);
    // max-width: 255px;
    text-align: center;
    transition: transform .5s;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px 0;
      max-width: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-hoverable:hover{
      transform: scale(1.2);
    }

    &.big-icon{
      .icon-holder{
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
    }

    .icon-holder {
      display: block;
      height: 50px;
      width: 50px;
      margin: 0 auto 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;


    }

    .small-title {
      margin: 0 0 17px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    .regular-text p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }

  .isBoxed {
    width: calc(33.33334% - 20px);
    max-width: none;
    padding: 30px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
    border-bottom: solid 7px #C60C30;

    @media (max-width: 1023px) {
      padding: 20px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    &:hover{
      transform: scale(1);
    }

    .small-title {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    .regular-text {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }

  .separator-content-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    .big-title {
      position: relative;
      width: 100%;
      text-align: center;
      font-family: $fontTitle;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
      color: black;

      @media (max-width: 650px) {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1.5px;
        margin-bottom: 25px;
      }

      // &:before {
      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   top: 9px;
      //   width: 100%;
      //   height: 1px;
      //   background-color: rgba(#262626, .2);
      // }
      //
      // &:after {
      //   content: '';
      //   position: absolute;
      //   left: 50%;
      //   bottom: -20px;
      //   transform: translateX(-50%);
      //   width: 60px;
      //   height: 4px;
      //   background-color: $colorAccent1;
      //
      //   @media (max-width: 650px) {
      //     height: 2px;
      //     bottom: -10px;
      //   }
      // }

      span {
        position: relative;
        z-index: 2;
        padding: 0 15px;
        background-color: $grey;

        &.white-bg {
          background-color: $white;
        }
      }
    }
  }
}

// --------------------------------------------------
// Block - Gallery Text
// --------------------------------------------------

.block-gallery-text {
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &.reversed-block {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .gallery-wrapper {
      &:before {
        right: 0;
        background: rgba(212, 19, 23, 0.52);
      }

      &:after {
        left: auto;
        right: 70px;
        background: rgba(0, 0, 0, 0.52);
      }

      .flickity-button {
        &.previous {
          left: 0;
        }

        &.next {
          left: 75px;
        }
      }
    }
  }

  .gallery-wrapper {
    position: relative;
    width: 60%;

    @media (max-width: 900px) {
      width: 100%;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;

      display: block;

      pointer-events: none;
      z-index: 3;
    }

    &:before {
      width: 70px;
      height: 100%;
      background: rgba(0, 0, 0, 0.52);
    }

    &:after {
      left: 70px;
      width: 35px;
      height: 50%;
      background: rgba(212, 19, 23, 0.52);
    }

    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  .flickity-button {
    bottom: 0;
    top: auto;

    background: $white;
    border-radius: 0;
    transform: none;

    &.previous {
      padding: 40px 25px 40px 50px;
      right: 75px;
      left: auto;

      @media (min-width: 768px) and (max-width: 1150px) {
        padding: 30px 25px 30px 50px;
      }
    }

    &.next {
      padding: 40px 50px 40px 25px;
      right: 0;

      &:before {
        transform: rotate(-180deg);
      }

      @media (min-width: 768px) and (max-width: 1150px) {
        padding: 30px 25px 30px 50px;
      }
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      margin: auto;
      width: 20px;
      height: 16px;

      background: url('/img/site/arrow-left.svg') no-repeat;
      background-size: contain;
    }

    &[disabled] {
      display: none;
    }

    svg {
      display: none;
    }
  }

  .gallery-content {
    padding: 35px 70px;
    width: 40%;

    @media (max-width: 1023px) {
      padding: 40px;
    }

    @media (max-width: 900px) {
      width: 100%;
      padding: 40px 20px;
    }

    .medium-title {
      color: $dark;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0 0 40px;
      font-weight: 600;
    }

    .capacity-text {
      @extend .small-title;
      color: $dark;
      text-transform: uppercase;
      font-weight: 600;

      .icon-people {
        margin-right: 10px;
        color: $light;
      }
    }

    .reading-text {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;

      a {
        color: $colorAccent1;
      }
    }

    .site-btn {
      margin-top: 50px;
    }
  }
}

// --------------------------------------------------
// Block - Gallery Text
// --------------------------------------------------

.block-partners {
  padding: 0;

  .grid-text {
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 767px) {
    }
  }

  .big-title {
    position: relative;
    width: 100%;
    text-align: center;
    font-family: $fontTitle;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 0 50px;
    color: black;

    @media (max-width: 650px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.5px;
      margin-bottom: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      width: 100%;
      height: 1px;
      background-color: rgba(#262626, .2);
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background-color: $colorAccent1;

      @media (max-width: 650px) {
        height: 2px;
        bottom: -10px;
      }
    }

    span {
      position: relative;
      z-index: 2;
      padding: 0 15px;
      background-color: $grey;

      &.white-bg {
        background-color: $white;
      }
    }
  }

  .partners-cta {
    background-color: $grey;
    padding: 80px 20px;

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .partners-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1520px;
      margin: 0 auto;

      &.page {
        .partners-list {
          &:not(.big) {
            margin-bottom: 40px;
          }

          .partial-partner {
            margin-bottom: 40px;
          }
        }
      }
    }

    .partners-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1100px) {
        flex-wrap: wrap;
      }

      @media (max-width: 650px) {
        flex-direction: column;
      }

      &.big {
        .partial-partner {
          width: calc(50% - 10px);
          min-height: 250px;

          @media (max-width: 1100px) {
            width: calc(50% - 10px);
            margin-bottom: 20px;
            min-height: 170px;
          }

          @media (max-width: 650px) {
            width: 100%;
            min-height: 140px;
          }
        }
      }
    }

    .partial-partner {
      width: calc(25% - ((20px * 3) / 4));
      height: 170px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media (max-width: 1100px) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
      }

      @media (max-width: 650px) {
        width: 100%;
        min-height: 140px;
      }

      img {
        width: 70%;
        max-width: 70%;
      }
    }

    .site-btn {
      margin-top: 50px;

      @media (max-width: 1100px) {
        margin-top: 30px;
      }
    }
  }
}

// --------------------------------------------------
// Block - Testimonials001
// --------------------------------------------------

.testimonials {
  @extend .site-block;;
  color: #3fa2f7;

  .testimonials-heading-wrapper {
    margin-bottom: 60px;
  }

  .testimonials-heading {
    margin-top: 0;
    text-align: center;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials-slider {
    .flickity-viewport {
      height: 380px;
    }

    .is-selected {
      opacity: 1 !important;

      .slider-skeleton, .slider-skeleton p {
        color: inherit !important;
        background: linear-gradient(to top, transparent, transparent) repeat-x !important;
      }
    }

    .testimonials-slider-item {
      width: 100%;
      max-width: 524px;
      margin: 0 auto;
      padding: 0 10px;
      opacity: .5;
      border-radius: 3px;
      color: #3fa2f7;

      .testimonials-cards {
        padding: 50px;
        line-height: 25px;
        border: 1px solid #e4f1fd;
      }

      .testimonials-item-heading {
        font-size: 19px;
        font-weight: 400;
        margin: 0;

        .slider-skeleton {
          color: transparent;
          /* background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x; */
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-infos {
        display: inline-block;
        font-size: 14px;
      }

      .testimonials-rating {
        display: inline-block;

        // .testimonials-rating-items{
        //   display: block;
        //   background-color: #3fa2f7;
        //   width: 20px;
        //   height: 20px;
        // }
      }

      .testimonials-item-date {
        margin: 0;

        .slider-skeleton {
          color: transparent;
          background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-text {
        margin: 10px 0 0 0;

        .slider-skeleton {
          p {
            display: inline;
            color: transparent;
            background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
            background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
            background-size: 1px 12px;
            background-position: center;
            -webkit-transition: 0.5s ease all;
            transition: 0.5s ease all;
          }

        }
      }
    }
  }

  .flickity-prev-next-button {
    @media(min-width: 650px) {
      display: none;
    }

    .flickity-button-icon {
      fill: #3fa2f7;
    }
  }
}

// --------------------------------------------------
// Block - Testimonials002
// --------------------------------------------------

.testimonials002 {
  @extend .site-block;;
  max-width: 1100px;
  margin: 0 auto;
  color: #3fa2f7;

  .testimonials002-wrapper {
    @extend .site-max-width;
  }

  .testimonials002-title {
    text-align: center;
    margin-top: 0;
    text-align: center;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials002-slider {
    width: 100%;
    margin: 0 auto 40px;
    text-align: center;

    .flickity-viewport {
      height: 200px;
    }

    .flickity-button-icon {
      width: 50%;
      height: 50%;
      fill: #3fa2f7;
    }

    .flickity-prev-next-button {
      top: 35%;

      &.previous {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }

    .testimonials002-slider-item {
      width: 100%;

      .testimonials002-text {
        display: inline-block;
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        line-height: 2;
        font-size: 18px;

        @media(max-width: 440px) {
          width: 80%;
        }
      }
    }
  }

  .testimonials002-sliderNav {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;

    .is-selected {
      opacity: 1 !important;
    }

    .slider-item {
      width: 25%;
      text-align: center;
      opacity: .5;

      .slider-item-heading {
        font-weight: 400;
      }

      img {
        width: 50px;
        margin-left: 0 auto;
      }
    }
  }
}

.block-image001 {
  margin-bottom: 0;

  img {
    display: block;
    width: 100%;
  }
}

.block-image001.with-info {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: calc(1110px + 45px * 2);

  @media(max-width: 900px) {
    flex-direction: column;
  }

  .info-img {
    display: flex;
    align-items: center;
    width: 68%;
    height: auto;

    @media(max-width: 900px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .image-info {
    width: calc(100% - 68%);
    padding: 40px;
    background: #000;
    color: #fff;

    @media(max-width: 900px) {
      width: 100%;
    }

    h3 {
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 31px;

      margin: 0;
      padding: 0 0 30px 0;

      color: #FFFFFF;
    }

    ul,
    ul li {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: normal;

      color: #FFFFFF;
    }

    ul {
      margin: 0;
      padding: 0 0 30px 0;
      list-style-type: none;
    }

    ul li {
      margin-bottom: 20px;
      font-weight: 800;
      padding-left: 55px;

      &:before {
        content: '▶';
        font-size: 12px;
      }

      @media(max-width: 900px) {
        font-size: 12px;
      }
    }

    ul {
      counter-reset: li;

      li {
        position: relative;
        counter-increment: li;

        &:before {
          content: counter(li);
          width: 40px;
          height: 25px;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 21px;

          color: #000000;
          text-align: center;
          line-height: 25px;

          z-index: 5;
        }

        &:after {
          content: '';
          width: 40px;
          height: 25px;
          background: url('/img/site/li.svg');
          background-position: center;
          background-size: 100% auto;
          background-repeat: no-repeat;

          position: absolute;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          font-size: 16px;
          font-weight: 600;

          z-index: 2;
        }
      }
    }
  }
}

.block-video001 {
  position: relative;
  @extend .site-max-width;
  @extend .site-block;

  margin-top: 0;
  padding-top: 0;

  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .player-btn {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .8);
      z-index: 1;

      transition: all 2s ease;
    }
  }

  &:hover {
    .player-btn {
      &:before {
        background-color: rgba(0, 0, 0, .35);
      }
    }
  }

  .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    cursor: pointer;
  }

  .block-media {
    width: 100%;
  }

  .block-media embed,
  .block-media iframe,
  .block-media object {
    border: 0;
  }

  .block-media-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .block-media-video embed,
  .block-media-video iframe,
  .block-media-video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video002 {
  position: relative;
  width: 100%;
  padding: 100px 40px 0;

  @media (max-width: 767px) {
    padding: 40px 20px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100% - 100px);
    background-color: $dark;

    @media (max-width: 767px) {
      height: calc(100% - 60px);
    }
  }

  .video002-wrapper {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video002-heading {
      position: relative;
      display: flex;
      max-width: 810px;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 44px;
      line-height: 71px;
      text-align: center;
      color: $white;
      margin: 0 0 20px;

      @media (max-width: 1023px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        background-color: #FFD600;
        border-radius: 100%;
      }

      &:before {
        left: -55px;
        // margin-right: 30px;

        @media (max-width: 767px) {
          margin-right: 10px;
        }
      }

      &:after {
        right: -55px;
        // margin-left: 30px;

        @media (max-width: 767px) {
          margin-left: 10px;
        }
      }
    }

    .video002-text {
      position: relative;
      max-width: 350px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $white;
      margin: 0 0 70px;

      @media (max-width: 767px) {
        margin-bottom: 40px
      }
    }

    .video002-video {
      position: relative;
      display: block;
      width: 100%;
      max-width: 920px;
      transition: opacity ease-out .2s;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(black, .5);
        opacity: 0;
        transition: opacity ease-out .2s;
        pointer-events: none;

        @media (max-width: 767px) {
          opacity: 1;
        }
      }

      &:hover:before {
        opacity: 1;
      }

      .video-image {
        width: 100%;
        display: inline-block;
      }

      .player-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: 0 auto;
        width: 60px;
        height: 60px;
        pointer-events: none;

        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
        }
      }
    }


  }
}

.video003 {
  position: relative;
  width: 100%;
  padding: 100px 40px 0;

  @media (max-width: 767px) {
    padding: 40px 20px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100% - 100px);
    background-color: $dark;

    @media (max-width: 767px) {
      height: calc(100% - 60px);
    }
  }

  .video002-wrapper {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video002-heading {
      position: relative;
      display: flex;
      max-width: 810px;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 44px;
      line-height: 71px;
      text-align: center;
      color: $white;
      margin: 0 0 20px;

      @media (max-width: 1023px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        background-color: #FFD600;
        border-radius: 100%;
      }

      &:before {
        left: -55px;
        // margin-right: 30px;

        @media (max-width: 767px) {
          margin-right: 10px;
        }
      }

      &:after {
        right: -55px;
        // margin-left: 30px;

        @media (max-width: 767px) {
          margin-left: 10px;
        }
      }
    }

    .video002-text {
      position: relative;
      max-width: 350px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $white;
      margin: 0 0 70px;

      @media (max-width: 767px) {
        margin-bottom: 40px
      }
    }

    .flickity-viewport {
      width: 100%;

      .flickity-prev-next-button {
        width: 45px !important;
        height: 45px !important;
      }
    }

    .video003-slider {
      width: 100%;

      .video002-video {
        position: relative;
        display: block;
        width: 100%;
        max-width: 920px;
        transition: opacity ease-out .2s;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(black, .5);
          opacity: 0;
          transition: opacity ease-out .2s;
          pointer-events: none;

          @media (max-width: 767px) {
            opacity: 1;
          }
        }

        &:hover:before {
          opacity: 1;
        }

        .video-image {
          width: 100%;
          display: inline-block;
        }

        .player-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          margin: 0 auto;
          width: 60px;
          height: 60px;
          pointer-events: none;

          @media (max-width: 767px) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }


  }
}


// --------------------------------------------------
// BasicLightbox pluggin
// --------------------------------------------------

.basicLightbox{
  background: rgba(0, 0, 0, 0.9);

  .basicLightbox__placeholder{
    position: relative;
  }

  .video-exit-button{
    position: absolute;
    top: -30px;
    left: -30px;
    width: 40px;
    height: 40px;
    opacity: .5;
    transition: opacity .5s;

    &:hover{
      cursor: pointer;
      opacity: 1;
    }

    .exit-button-bar:first-child{
      height: 3px;
      width: 20px;
      display: block;
      background-color: white;
      transform:  rotateZ(45deg) translateX(9px);;
    }

    .exit-button-bar:nth-child(2){
      height: 3px;
      width: 20px;
      display: block;
      background-color: white;
      transform: rotateZ(-45deg) translateX(2px) translateY(7px);
    }
  }
}
// --------------------------------------------------
// Block - image002
// --------------------------------------------------

.imageFullWidth {
  margin-top: 50px;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  img {
    width: 100%;
  }
}

// --------------------------------------------------
// Block - image003
// --------------------------------------------------

.image003 {
  @extend .site-block;
  padding: 100px 0;

  @media(max-width: 767px) {
    padding: 50px 0;
  }

  @media(max-width: 400px) {
    padding: 30px 0;
  }

  .image003-heading {
    margin: 0 0 50px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 71px;
    text-align: center;
    padding: 0 20px;

    @media(max-width: 767px) {
      margin: 0 0 20px;
      font-size: 32px;
      line-height: 40px;
    }

    p {
      margin: 0;
    }
  }

  .image003-text {
    margin: 0 auto 50px;
    max-width: 400px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    padding: 0 20px;

    @media(max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .image003-wrapper {
    @extend .site-max-width;
    max-width: calc(1110px + 75px * 2);

    img {
      width: calc(50% - 2px);
    }
  }
}

// --------------------------------------------------
// Block - Logo Section
// --------------------------------------------------

.logos-section {
  padding: 100px 0 30px;
  background: $dark;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  .logos-wrapper {
    @extend .site-max-width;
    max-width: calc(1310px + 75px * 2) !important;
  }

  .logo-heading {
    margin: 0 0 60px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 71px;
    text-align: center;
    padding: 0 20px;
    color: $white;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .logos-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .logos-item {
      width: auto;
     text-align: center;
     margin: 0 2% 80px 2%;

      @media (max-width: 767px) {
        width: calc(50% - 10px);
      }

      &:first-child, &:nth-child(5), &:nth-child(6){
        img{
          max-height: 75px;
        }
      }

      img {
        max-width: 100%;
        max-height: 60px;

        &.rounded, &:first-child, &:nth-child(5), &:first-child(6){
          max-height: 75px;
        }
      }
    }
  }
}


// --------------------------------------------------
// Geoloc Map & Addresses listing
// --------------------------------------------------
.geomap001 {
  position: relative;
  width: 100%;
  padding-top: 100px;
  background: #F1F1F1;

  @media (max-width: 767px) {
    padding-top: 45px;
  }

  .geomap-title {
    width: 100%;
    margin-top: 0;
    margin-bottom: 65px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 71px;
    /* identical to box height */

    text-align: center;

    color: #000000;

    @media (max-width: 767px) {
      font-size: 32px;
      margin-bottom: 50px;
    }

  }

  .infos-dealer-name{
    font-size: 18px;
  }

  .geomap001-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 670px;
    max-width: 1110px;
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: inherit;
      height: auto;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .geomap {
      position: relative;
      width: 100%;
      height: 670px;
      background: #F1F1F1;
    }

    .custom-marker {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      cursor: pointer;

      &::after {
        content: '';
        background-image: url('/img/site/TOIT_NOIR.svg');
        background-size: 24px 16px;
        display: inline-block;
        width: 24px;
        height: 16px;
        margin: -10px 0 0 -10px;
      }

      &.is-active,
      &.force-active{
        background: transparent;
      }
      &.is-active::after,
      &.force-active:after {
        content: '';
        background-image: url('/img/site/TOIT_ROUGE.svg');
        background-size: 24px 16px;
        display: inline-block;
        width: 24px;
        height: 16px;
        margin: -10px 0 0 -10px;
      }

      div {
        position: absolute;
        left: 50%;
        top: 0;
        min-width: 200px;
        text-align: center;
        transform: translate(-50%, -150%);
        background: #fff;
        border: 1px solid rgba(0, 0, 0, .35);
        padding: 5px 10px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        font-size: 13px;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);

          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;

          border-top: 7px solid #fff;
        }
      }
    }

    .geomap-infos {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 350px;
      padding-left: 30px;

      overflow: hidden;
      overflow-y: scroll;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      color: #000000;

      @media (max-width: 767px) {
        max-width: inherit;
        height: auto;
        padding-right: 20px;
      }

      a {
        color: rgba(0, 0, 0, .65);
      }

      hr {
        width: 100%;
        height: 1px;
        margin: 15px 0 10px 0;
        padding: 0;
        border: none;
        background: #000;
      }
    }

    .geoForm {
      position: relative;
    }

    .geo-input {
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      margin-bottom: 5px;
      background: #FFFFFF;
      border: 1px solid #DCDCDC;
      box-sizing: border-box;
      border-radius: 3px;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, .3);
      text-transform: uppercase;
      outline: none;
    }

    .flickity-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;
      border-radius: 44px;
      transition: transform ease-out .2s;
      transform: translate(-5px, 4px) scale(.8);
      background: #000;
      color: #fff;

      &:before {
        content: "\E82B";
        position: relative;
        top: -1px;
        font-family: fontello;
        font-size: 12px;
        color: #fff;
      }
    }
    // [data-place]
    .infos-address-container{
      background-color: white;
    }

    [data-place]{
      padding-bottom: 20px;
      // margin-bottom: 30px;
      border-bottom: 1px solid #EAEAEA;
      padding: 10px 10px 10px 10px;
      border: 2px solid #f1f1f1;
      font-weight: 500;
      transition: all 0.35s ease;
      background-color: white;

      h4 {
        margin-top: 0;
      }

      &.is-active {
        border-color: #000;
      }

      &.force-active {
        background: $colorAccent2;
        color: #fff;
        border-color: #000;

        a {
          color: #fff;
        }
      }
    }
  }
}
