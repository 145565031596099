.hero-banner {
  .flickity-viewport {
    height: calc(100vh - 92px);
    max-height: 850px;

    @media (max-width: 1024px) {
      height: calc(100vh - 72px);
    }
  }

  .hero-carousel-item {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 40px;

    @media (max-width: 767px) {
      height: 60vh;
      padding: 40px 20px;
    }

    &.logoTop{
      .carousel-heading{order:2}
      .carousel-text{order:3}
      .carousel-subLogos{
        display: flex;
        order:4;

        img{
          width: 100%;
          max-width: 130px;
          margin: 0 10px auto 0;

          @media (max-width: 767px) {
            width: 100%;
            max-width: 130px;
            margin: 0 auto;
          }

          &:last-child{
            margin-right: 0;
          }
        }
      }
      .carousel-logo{order:1}
    }

    .carousel-heading {
      margin: 50px 0 10px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: $colorAccent3;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      @media (max-width: 599px) {
        margin: 25px 0 10px;
      }
    }

    .carousel-text {
      font-family: $fontTitle;
      width: 100%;
      max-width: 730px;
      margin: 0 0 75px;
      font-style: normal;
      font-weight: 500;
      font-size: 37px;
      line-height: 41px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 20px;
      }

      @media (max-width: 599px) {
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 20px;
      }

      &.logoGuarantee-center{
        margin-bottom: 0;
      }

      p{
        margin: 0 0 38px;
      }
    }

    .carousel-button{
      @extend .site-btn;
      margin: 30px 0 0;
      padding: 10px 25px;
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      background-color: $white;
      border: 1px solid $white;
      order: 10;
    }

  }

  .previous {
    left: 20px;
  }

  .next {
    right: 20px;
  }
  .flickity-button{
    @media(max-width: 1024px){
      background-color: $white;
    }
  }
  .flickity-prev-next-button {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    transition: transform ease-out .2s;

    @media (max-width: 767px) {
      display: none;
    }

    &:before {
      position: relative;
      top: -1px;
      font-family: fontello;
      font-size: 12px;
      color: black;
    }

    &.previous:before {
      content: "\E823";

    }

    &.next:before {
      content: "\E82B";
    }

    &:hover {
      transform: translateY(-50%) scale(1.2);
    }
  }

  .carousel-logo {
    display: block;
    margin: 0;
    width: 112px;

    &.logo-big{
      width: 166px;
    }
  }

  .carousel-logoGuarantee {
    position: absolute;
    right: 60px;
    bottom: 40px;
    width: 80px;

    @media (max-width: 767px) {
      right: 20px;
      bottom: 20px;
      max-height: 60px;
    }

    &.logoGuarantee-center{
      position: static;
    }
  }

  .carousel-logoExclusives{
    margin-right: 50px;

    @media (max-width: 767px) {
      width: 80px;
    }

    // img{
      // width: 100%;
    // }
  }
}
