// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Oswald', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTitleSerif: 'Oswald', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'IBM Plex Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTextLight: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #000000;
$colorAccent2: #C20E30;
$colorAccent3: #FFD600;
$colorAccent4: #FFFFFF;

//Modules Colors
$colorM1: #13AEEE ;
$colorM2: #13AEC3 ;
$colorM3:#13AE87;
$colorM4:#4FD3B0;
$colorM5:#4FD389;
$colorM6:#4FD360;
$colorM7:#FE874E;
$colorM8:#FE8777;
$colorM9:#FE87BE;
$colorM10:#CF065A;
$colorM11:#CF068E;
$colorM12:#CF06C8;
$colorM13:#845AB8;
$colorM14:#845AD8;
$colorM15:#845AF6;


$colorTextDark: #000;
$colorTextLight: #000;

// Blocks and Text
$dark: #000;
$grey: #464646;
$light: #A0ACB9;
$lighter: #E5EAEF;
$alternateWhite: #f6f8fc;
$white : #fff;
