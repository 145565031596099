.top-banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  padding: 25px 45px;
  background: $colorAccent2;

  &.is-active {
    display: block;
  }

  a {
    text-decoration: underline;
  }

  .top-banner-text {
    margin: 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $white;

    @media (max-width: 1059px) {
      font-size: 14px;
    }

    @media (max-width: 599px) {
      font-size: 13px;
    }
  }

  .top-banner-close {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    font-size: 26px;
    cursor: pointer;

    @media (max-width: 767px) {
      right: 0;
      top: 20px;
    }
  }
}

// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: absolute;
  left: 0;
  top: 71px;
  width: 100%;
  padding: 20px 30px;
  z-index: 10;

  font-family: $fontTitle;
  background: $dark;

  overflow: hidden;

  @media (max-width: 911px) {
    top: 92px;
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);

    &.hide-nav-bar {
      // -webkit-transform: translate(0, -100%);
      // -ms-transform: translate(0, -100%);
      // transform: translate(0, -100%);
      // -webkit-backface-visibility: hidden;
    }
  }

  &.on-top {
    top: 0;
  }

  @media (max-width: 1400px) {
    padding: 20px 40px;
  }

  @media (max-width: 1299px) {
    padding: 20px 30px;
  }

  @media (max-width: 1100px) {
    padding: 10px 20px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header-logo {
    margin-right: auto;
    margin-left: 15px;

    img {
      height: 50px;
    }
  }

  .language-link {
    position: relative;
    padding: 0 30px 0 0;
    margin-right: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    opacity: .5;

    @media (max-width: 767px) {
      display: none;
    }

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);

      width: 1px;
      height: 200px;
      background: rgba(255, 255, 255, .50);

      z-index: 2;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-family: $fontTitle;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: normal;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0 15px;

      transition: all 0.35s ease;

      &:after {
        content: '';
        position: absolute;
        bottom: -35px;
        left: 0;
        width: 100%;
        height: 0;
        background-color: $colorAccent2;
        transition: height ease-out .2s;
      }

      &:hover:after {
        height: 5px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active:after {
        height: 10px;
      }

      &.cta-button{
        padding: 10px 20px;
        background-color: $colorAccent2;
        color: $white;
        border-radius: 2px;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-phone {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $colorAccent3;
      opacity: 1;
      padding: 0 0 0 30px;
      margin: 0 0 0 0;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 1px;
        height: 200px;
        background: rgba(255, 255, 255, .25);
        z-index: 2;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      a {
        transition: all 0.35s ease;
        opacity: 1;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      @media (max-width: 1024px) {
        display: block;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .site-btn {
      margin-left: 15px;
      margin-right: 30px;

      @media (max-width: 1400px) {
        padding: 10px 20px;
        font-size: 12px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1024px) {
        display: block;
        margin-left: 40px;
      }
    }
  }
}
