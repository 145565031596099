// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: inline-block;
  padding: 12px;
  border: 2px solid $colorAccent3;
  background: $colorAccent3;
  color: #000;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s ease-in-out;
  // transition-duration: .3s;

  border-radius: 4px;

  -webkit-appearance: none;

  &:hover {
    border-color: $colorAccent3;
    background: $colorAccent3;
    transform: scale(1.05);
  }

  &.is-outlined {
    padding: 15px 25px;
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
      }
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    border-color: $colorAccent3;
    background: $colorAccent3;
  }

  &.is-white {
    background: #fff;
    color: #000;
    border-color: #fff;
    min-width: 200px;
  }
}

.link-text-btn {
  padding: 15px 0;
  padding-right: 45px;
  border-bottom: 3px solid $colorAccent3;
  color: $colorAccent3;
  font-family: $fontTitleSerif;
  text-transform: uppercase;
}

@keyframes pulse_btn {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
