// --------------------------------------------------
// Page About
// --------------------------------------------------

.about {
  .site-toogle {
    position: absolute;
    top: -55555555px;
    left: -555555555px;
    z-index: -1;

    &.is-active {
      position: relative;
      top: auto;
      left: auto;
      z-index: 2;
    }
  }
}
