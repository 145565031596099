// --------------------------------------------------
// Footer
// --------------------------------------------------

.main-footer {
  position: relative;
  display: block;
  background: $dark;
  padding: 50px 0;
  border-top: 1px solid #505050;

  @media(max-width: 1024px) {
    padding-bottom: 35px;
  }

  .footer-container {
    @extend .site-max-width;
    position: relative;
    max-width: calc(1110px + 75px * 2);

    display: flex;
    justify-content: space-between;
    color: #fff;

    @media (max-width: 655px) {
      flex-direction: column;
    }

    .footer-column {
      display: flex;
      flex-direction: column;
      flex-direction: column;
      align-items: flex-start;

      @media(max-width: 655px) {
        margin: 0 auto 30px;
        align-items: center;
      }

      &:last-child {
        align-items: flex-end;

        @media(max-width: 655px) {
          margin-bottom: 0;
          align-items: center;
        }
      }

      // img{
      //   margin-bottom: 75px;
      // }

      .logo-img {
        width: 166px;

        // margin-bottom: 75px;
        @media(max-width: 655px) {
          width: 130px;
          margin-bottom: 20px;
        }
      }

      .social-logo-container {
        display: flex;
        flex-direction: row;

        .youtube-link {
          width: 30px;
          height: 30px;
          margin-top: 16px;

          img {
            width: 100%;
            margin: 0;
          }
        }

      }

      .social-logo {
        margin-top: 20px;
        margin-right: 15px;
        width: 10px;
      }

      .linkedin {
        width: 24px;
      }

      .logoGuarantee-img {
        width: 66px;

        @media(max-width: 655px) {
          margin: 0 0 20px;
          width: 48px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .footer-infos {
    margin-top: 10px;
    display: flex;
    align-items: center;

    @media(max-width: 699px) {
      margin-top: 20px;
    }

    .phone-container {
      position: relative;
      margin-right: 30px;
      display: flex;
      flex-direction: column;

      &:first-child::after {
        content: '';
        position: absolute;
        top: 0;
        right: -15px;
        bottom: 0;
        border: .5px solid $colorAccent3;
      }
    }

    .infos-item {
      position: relative;
      margin-bottom: 5px;
      color: $colorAccent3;

      @media(max-width: 350px) {
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-text {
    margin-top: 65px;

    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;

    @media(max-width: 699px) {
      margin-top: 75px;
      margin-bottom: 35px;
    }

    @media(max-width: 655px) {
      font-size: 14px;
      text-align: center;
    }
  }

  .footer-copyrightText {
    margin-top: 120px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;

    @media(max-width: 655px) {
      margin-top: 30px;
      font-size: 14px;
      text-align: center;
    }
  }
}


.main-footer {
  .grid-logos {

    ul,
    li {
      padding: 0;
      margin: 0;
      list-style-type: none
    }

    ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 11%;
      right: 170px;

      @media(max-width: 1024px) {
        transform: scale(.5);
        right: 0;
      }

      @media(max-width: 699px) {
        width: 100%;
        justify-content: center;
        transform: scale(.5);
        right: 0;
        top: 175px;
      }

      img {
        display: block;
        width: auto;
        max-height: 70px;
        margin-left: 8px;
      }
    }
  }
}
