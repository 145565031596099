.depositaires {

  .block-intro001 {
    // margin-top: 0;
    // margin-bottom: 15px;
    // padding-top: 60px;
    // padding-bottom: 0;


  }

  .block-intro001 + .slider003 {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 15px;
    padding-bottom: 0;
  }

  .grid003 {
    // max-width: 900px;
    margin: 0 auto;
    padding-bottom: 75px;

    .grid003-wrapper {
      .grid003-grid {
        .grid003-grid-items {

          &.col-5 {
            width: calc(98% / 4);
            max-width: 205px;

            @media screen and (max-width: 1024px) {
              width: calc(98% / 3);
              margin: 2%;
            }


            @media screen and ( max-width: 767px ) {
              width: 60%;
              padding: 0;
              max-width: inherit;
              min-height: 230px;
            }
          }

          &.with-logo {
            transform: inherit;

            .logo-wrapper {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }

          @media screen and ( max-width: 767px ) {
            min-height: 300px;
          }

        }
      }
    }
  }

  .cta003 {
    margin-top: 50px;
    margin-bottom: 200px;

    @media only screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 155px;
    }

    .cta003-wrapper {
      padding-bottom: 0;

      .cta003-container {
        padding: 40px 0 40px 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .block-video001 {
    padding-bottom: 0;
    margin-top: -50px;
    margin-bottom: 100px;

    @media only screen and (max-width: 767px) {
      margin-top: -50px;
      margin-bottom: 25px;
    }
  }

  .block-image001 {
    @extend .site-max-width;
    @extend .site-block;
    padding-bottom: 25px;
  }

  .contact-form-wrapper {
    .contact-form {
      .site-form {
        .form-input-wrapper {

          &.is-none {
            display: none;
          }
          &.is-display {
            display: block;
          }
        }
      }
    }
  }
}
