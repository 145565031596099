.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: none;
  background: rgba(0, 0, 0, .8);

  &.is-active {
    display: block;
  }
}

.modal-contact-form {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  display: none;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  transform: translate(-50%, -50%);
  background: #000;
  overflow: hidden;

  &.is-open {
    display: block;
  }

  @media (max-width: 767px) {
    width: calc(100vw - 50px);
    height: calc(100vh - 50px);
  }

  .form-input,
  .form-textarea {
    font-style: normal;
    transition: background-color .3s ease;

    &:focus {
      background-color: #6b6b6b;
    }

    &::placeholder {
      font-style: italic;
    }
  }

  .infos-contact-person {
    text-align: center;
  }
}

.modal-wrapper {
  height: 100%;
  width: 100%;
  max-width: 730px;
  padding: 110px 0;
  margin: 0 auto;
  overflow: scroll;

  @media (max-width: 767px) {
    padding: 100px 20px 50px;
  }
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 10px 7px;
  border-radius: 50%;
  background: $white;
  color: #000;
  font-weight: 600;
  font-size: 30px;
}

.modal-title{
  padding-bottom: 40px;
  margin: 0;
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: center;
  color: $white;
  border-bottom: 1px solid;
}

.modal-team {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 0;
  margin: 60px 0;
  border-top: 2px solid rgba(255, 255, 255, .3);
  border-bottom: 2px solid rgba(255, 255, 255, .3);

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.modal-member {
  @media (max-width: 767px) {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
  }

  .member-name {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 162.5%;
    text-align: center;
    color: $white
  }

  .member-role {
    font-family: IBM Plex Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.5%;
    text-align: center;
    color: $white
  }

  .member-email,
  .member-phone {
    display: block;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.5%;
    text-align: center;
    color: #B7B7B7;
  }
}
